import { Link } from 'gatsby';
import React from 'react';
import Animation from './Animation';

export default function Card({ data }) {
  return (
    <section id='about' className='about'>
      <div className='container'>
        {data.section_head ? (
          <div className='section-header'>
            <h2>{data.header}</h2>
            <div className='bar'></div>
          </div>
        ) : (
          ''
        )}

        <div className='row'>
          {data.cards.map((each_card, index) => (
            <div className='col-lg-3 mt-5' key={index}>
              <Link to={each_card.each_card_link}>
                <div className='icon-box'>
                  <img
                    className={each_card.card_img_class}
                    src={each_card.card_img}
                    alt={each_card.card_img_alt}
                  />
                  <h3>{each_card.card_head}</h3>
                  <p>{each_card.card_content}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      {data.animations ? <Animation /> : ''}
    </section>
  );
}
